import React, { useState, useEffect, useRef } from "react";
import { Image } from "./Image";
import { P } from "common/P/P";
import ImageUploadButton from "./ImageUploadButton";
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
} from "react-beautiful-dnd";
import { serviceConfig } from "configs";
import { ImageFile } from "pages/ProductList/ProductList";
import { toast } from "react-toastify";

interface IPropsImageUpload {
	slider?: boolean;
	singleUpload?: boolean;
	onImageUpload?: (files: ImageFile | ImageFile[]) => void;
	label?: string;
	dragAndDrop?: boolean;
	initialImages?: ImageFile[];
	productId?: string;
}

const ImageUpload: React.FC<IPropsImageUpload> = ({
	slider,
	singleUpload = false,
	onImageUpload,
	label,
	dragAndDrop = false,
	initialImages = [],
	productId,
}) => {
	const [imageFiles, setImageFiles] = useState<ImageFile[]>([]);
	const isInitialSetupDone = useRef(false);
	console.log("imageFiles", imageFiles);

	useEffect(() => {
		if (!isInitialSetupDone.current && initialImages.length > 0) {
			setImageFiles(initialImages);
			isInitialSetupDone.current = true;
		}
	}, [initialImages]);

	const handleImageUpload = (files: ImageFile | ImageFile[]) => {
		const fileArray = Array.isArray(files) ? files : [files];

		const uniqueFiles = [...imageFiles, ...fileArray].filter(
			(file, index, self) => {
				if (file instanceof File) return true;
				return (
					self.findIndex((f) => !(f instanceof File) && f.url === file.url) ===
					index
				);
			}
		);

		setImageFiles(uniqueFiles);

		if (onImageUpload) {
			onImageUpload(singleUpload ? uniqueFiles[0] : uniqueFiles);
		}
	};

	const handleDeleteImage = (imageSrc: string) => {
		console.log("Deleting image:", imageSrc);

		const index = imageFiles.findIndex((file) => {
			if (file instanceof File) {
				// Match blob URL for locally uploaded files
				return URL.createObjectURL(file) === imageSrc;
			} else if (file && file.url) {
				// Match server URL for existing images
				return `${serviceConfig.apiUrl}/get-image/${file.url}` === imageSrc;
			}
			return false;
		});

		if (index === -1) {
			toast.error("Image not found. Please refresh and try again.");
			console.error("Image not found in state:", imageSrc);
			return;
		}

		const imageFile = imageFiles[index];

		if (imageFile instanceof File) {
			// Remove locally uploaded file
			console.log("Removing locally uploaded image:", imageFile.name);
			setImageFiles((prev) => prev.filter((_, i) => i !== index));
			toast.success("Image deleted successfully");

			// Revoke blob URL to free memory
			const blobUrl = URL.createObjectURL(imageFile);
			URL.revokeObjectURL(blobUrl);
		} else if (imageFile && imageFile.url) {
			// Handle server-side image deletion
			const imagePath = imageFile.url;
			console.log("Deleting server-side image:", imagePath);

			fetch(`${serviceConfig.apiUrl}/products/${productId}/images`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ imagePath }),
			})
				.then((response) => response.json())
				.then((data) => {
					if (data && data.success) {
						setImageFiles((prev) => prev.filter((_, i) => i !== index));
						toast.success("Image deleted successfully");
					} else {
						toast.error(
							`Failed to delete image: ${data?.message || "Unknown error"}`
						);
						console.error("Failed to delete image:", data);
					}
				})
				.catch((error) => {
					toast.error("Error deleting image. Please try again.");
					console.error("Error deleting image:", error);
				});
		} else {
			toast.error("Invalid image data. Please refresh and try again.");
			console.error("Invalid image data:", imageFile);
		}
	};

	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) return;

		const reorderedFiles = Array.from(imageFiles);
		const [removedFile] = reorderedFiles.splice(result.source.index, 1);
		reorderedFiles.splice(result.destination.index, 0, removedFile);

		const updatedFiles = reorderedFiles.map((file, index) => {
			if (typeof file === "object" && !(file instanceof File)) {
				return { ...file, index };
			}
			return file;
		});

		setImageFiles(updatedFiles);

		if (onImageUpload) {
			onImageUpload(singleUpload ? updatedFiles[0] : updatedFiles);
		}
	};

	const getOrdinalNumber = (n: number): string => {
		const s = ["th", "st", "nd", "rd"];
		const v = n % 100;
		return n + (s[(v - 20) % 10] || s[v] || s[0]);
	};

	return (
		<div className="grid gap-6">
			{(!singleUpload || (singleUpload && imageFiles.length === 0)) && (
				<ImageUploadButton
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleImageUpload(Array.from(e.target.files!))
					}
				/>
			)}
			{slider ? (
				imageFiles.map((file, idx) => {
					const src =
						file instanceof File
							? URL.createObjectURL(file)
							: `${serviceConfig.apiUrl}/get-image/${file.url}`;
					return (
						<Image
							key={src}
							src={src}
							slider={slider}
							onDelete={() => handleDeleteImage(src)}
						/>
					);
				})
			) : dragAndDrop ? (
				<DragDropContext onDragEnd={handleDragEnd}>
					<Droppable droppableId="images" direction="horizontal">
						{(provided) => (
							<div
								className="grid gap-2 grid-cols-maxTwo"
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{imageFiles.map((file, idx) => {
									const src =
										file instanceof File
											? URL.createObjectURL(file)
											: `${serviceConfig.apiUrl}/get-image/${file.url}`;
									const imageLabel = `${getOrdinalNumber(idx + 1)} ${label}`;

									return (
										<Draggable key={src} draggableId={src} index={idx}>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className={`grid gap-2 ${
														snapshot.isDragging ? "opacity-50" : ""
													}`}
													style={{
														...provided.draggableProps.style,
														boxShadow: snapshot.isDragging
															? "0 0 .4rem #666"
															: "none",
													}}
												>
													<P size="p3" type="medium">
														{imageLabel}
													</P>
													<Image
														src={src}
														onDelete={() => handleDeleteImage(src)}
													/>
												</div>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			) : (
				<div className="grid gap-2 grid-cols-maxTwo">
					{imageFiles.map((file, idx) => {
						const src =
							file instanceof File
								? URL.createObjectURL(file)
								: `${serviceConfig.apiUrl}/get-image/${file.url}`;
						const imageLabel = `${getOrdinalNumber(idx + 1)} ${label}`;
						return (
							<div key={src} className="grid gap-2">
								<P size="p3" type="medium">
									{imageLabel}
								</P>
								<Image src={src} onDelete={() => handleDeleteImage(src)} />
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default ImageUpload;
